<template>
    <LiteYouTubeEmbed
        :id="videoId"
        poster="sddefault"
        webp="true"
    />
</template>

<script setup>
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import { ref, onMounted } from 'vue'

// Props
const props = defineProps({
    src: {
        type: String,
        required: true
    }
})

// State
const videoId = ref('')

// Extract Video ID
const extractVideoId = (url) => {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/[^\n\s\/]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    const match = url.match(regExp)

    if (match && match[1]) {
        videoId.value = match[1]
    } else {
        console.error('Invalid YouTube URL')
    }
}

// Life-cycle Hooks
onMounted(() => {
    extractVideoId(props.src)
})
</script>

<style scoped>
</style>
